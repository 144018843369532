<template>
  <div class="newListMain">
    <div class="cont">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="20">
        <van-cell v-for="item in dataList" :key="item.id">
          <div class="item" v-if="item.picture">
            <div class="left_img" style="float:left;">
              <van-image class="left_img" width="130" height="80" @click="imgPreview(item.picture)" :src="imgStream(item.picture)"></van-image>
            </div>
            <div class="right_text" style="float:left;">
              <h4 style="margin: 0">{{item.position}}</h4>
              <span>{{item.updateTime}}</span>
              <span>固定车位剩余: <strong>{{item.remain}}</strong></span>
              <span>临时车位剩余: <strong>{{item.tempCarportCount - item.tempUseCount}}</strong></span>
            </div>
          </div>
          <van-cell v-else  :value="item.updateTime" >
            <template #title>
              <h4  class="text_title">{{item.position}}</h4>
            </template>
            <template #label>
              <p style="color: #999" class="text_label">固定车位剩余: <strong>{{item.remain}}</strong>, 临时车位剩余: <strong>{{item.tempCarportCount - item.tempUseCount}}</strong></p>
            </template>
          </van-cell>
        </van-cell>
      </van-list>
      <van-empty description="没有数据哦"  v-if="dataList.length < 1"/>
    </div>
  </div>
</template>

<script>
import {getImageStream} from '@/utils'
import { ImagePreview } from 'vant'

export default {
  data () {
    return {
      loading: false,
      finished: false,
      dataList: [],
      page: 0,
      limit: 10
    }
  },
  methods: {
    getDataList () {
      this.page++
      this.$toast.loading({duration: 0,message: '加载中...',forbidClick: true,});
      this.$httpApp({
        url: this.$httpApp.adornUrl('/wxapp/future/parking/list'),
        method: 'post',
        params: this.$httpApp.adornParams({
          limit: this.limit,
          page: this.page,
          orgId: this.$orgId,
        })
      }).then(({data})=> {
        if (data.code == 0) {
          if (this.dataList.length === data.page.totalCount) {
            this.finished = true
          }
          this.dataList = this.dataList.concat(data.page.list)
          this.loading = false;
          this.$toast.clear()
        }
      })
    },
    imgPreview (path) {
      let src = getImageStream(path)
      ImagePreview([src]);
    },
    imgStream (path) {
     return  getImageStream(path)
    }
  },
  created() {
    this.getDataList()
  }
}
</script>

<style lang="scss" scoped>
::v-deep .item {
  padding: 20px;
  height: 100%;
  .left_img {
  }
  .right_text {
    float: left;
    margin-left: 20px;
    h4 {
      line-height: 40px;
      height: 40px;
      font-size: 32px;
    }
    span {
      font-size: 22px;
      color: #999;
      display: block;
      height: 40px;
    }
  }
}
::v-deep .text_label {
  width: 300px;
  white-space: nowrap;
  margin: 20px 0 10px 0;
  font-size: 22px;
}
::v-deep .text_title {
  font-size: 32px;
  margin: 0;
  padding: 0;
}
</style>
